import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { QuestionService } from '../../_services/questions.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  public correctAnswer;
  public isRightChoice = "";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _QuestionsService: QuestionService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.form = this.formBuilder.group({
      question: ['', Validators.required],
      option1: ['', Validators.required],
      option2: ['', Validators.required],
      option3: ['', Validators.required],
      option4: ['', Validators.required],
      isRightChoice: '',
      isActive: true
    }, {
      });
    if (!this.isAddMode) {
      this._QuestionsService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              // console.log(response.data);



              this.form.patchValue(response.data.question);
              this.form.patchValue({ option1: response.data.questionOptions[0].option });
              this.form.patchValue({ option2: response.data.questionOptions[1].option });
              this.form.patchValue({ option3: response.data.questionOptions[2].option });
              this.form.patchValue({ option4: response.data.questionOptions[3].option });

              if (response.data.questionOptions[3].isRightChoice == "true") {
                this.isRightChoice = '4';
              } else if (response.data.questionOptions[2].isRightChoice == "true") {
                this.isRightChoice = '3';
              } if (response.data.questionOptions[1].isRightChoice == "true") {
                this.isRightChoice = '2';
              } if (response.data.questionOptions[0].isRightChoice == "true") {
                this.isRightChoice = '1';
              }

              this.form.patchValue({ isRightChoice: this.isRightChoice });
            } else {
              this._AlertService.error(response.message);
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }

  onCancel() {
    this._location.back();
  }
  onSubmit() {

    if (this.form.value.question == '') {
      this._AlertService.error('Please enter question.');
      return false;
    }
    if (this.form.value.option1 == '') {
      this._AlertService.error('Please enter option1.');
      return false;
    }
    if (this.form.value.option2 == '') {
      this._AlertService.error('Please enter option2.');
      return false;
    }
    if (this.form.value.option3 == '') {
      this._AlertService.error('Please enter option3.');
      return false;
    }
    if (this.form.value.option4 == '') {
      this._AlertService.error('Please enter option4.');
      return false;
    }
    if (this.form.value.isRightChoice == '') {
      this._AlertService.error('Please select correct choice.');
      return false;
    }


    this.submitted = true;
    this.spinner.show();
    if (this.isAddMode) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this._QuestionsService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  private update() {
    this._QuestionsService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }
}
