import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_alert';
import { BatchService } from '../../_services/batch.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public batches;  
  public courses;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public isActive='';
  public course='';
  public name='';

  constructor(
    private _AlertService: AlertService,
    private _BatchService: BatchService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getBatches();
    this.getAllCourses(); 
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getBatches();
    }
  }

  getBatches() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      isActive:this.isActive,
      course:this.course,
      name:this.name,
    };
    this._BatchService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.batches = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          this._AlertService.error(response.message);
        }

         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getBatches();
  }

  onFilterByName(key){
    if (key==''){
      this.name = '';
    } else {
      this.name = key;
    }
    this.getBatches();
  }

  onFilterByCourse(key){
    if (key==''){
      this.course = '';
    } else {
      this.course = key;
    }
    this.getBatches();
  }

  getAllCourses(){
    this.spinner.show();
    this._BatchService.getCourses().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.courses = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

}
