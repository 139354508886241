import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class BlogsService {

  constructor(private _ApiService: ApiService) { }

  getAll() {
    return this._ApiService.get('blogs');
  }

  getById(id) {
    return this._ApiService.get('blogs/'+ id);
  }

  create(data) {
      return this._ApiService.post('blogs', data);
  }

  update(id, data) {
      return this._ApiService.put('blogs/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('blogs/' + id);
  }
}
