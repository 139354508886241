import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CmspagesRoutingModule } from './cmspages-routing.module';
import { IndexComponent } from './index/index.component';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from 'ckeditor4-angular';


@NgModule({
  declarations: [IndexComponent, CreateUpdateComponent],
  imports: [
    CommonModule,
    CmspagesRoutingModule,
    FormsModule,
    NgbModule,
    CKEditorModule,
    ReactiveFormsModule
  ]
})
export class CmspagesModule { }
