import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../_services/auth.service';
import { environment } from '../../../environments/environment';
import {UserService} from '../../_services/user.service';
import { Router } from '@angular/router';
declare var showLogoutPopup: Function;
declare var hideLogoutPopup : Function;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public iconOnlyToggled = false;
  public sidebarToggled = false;  
  public user;
  public environment = environment;

  constructor(
    private _UserService: UserService,
    private _AuthService:AuthService,
    private router: Router,
    config: NgbDropdownConfig
    ) {
    config.placement = 'bottom-right';
    this.user = this._AuthService.getUserInfo();
  }

  ngOnInit() {
  } 
  openLogoutPopup() {
    showLogoutPopup();
  }
  closeLogoutPopUp(){
    hideLogoutPopup();
  } 
  public logout() {   
     this._UserService.logout();
     this.router.navigate(['/user/login']);
  }

  // toggle sidebar in small devices
  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  // toggle sidebar
  toggleSidebar() {
    let body = document.querySelector('body');
    if((!body.classList.contains('sidebar-toggle-display')) && (!body.classList.contains('sidebar-absolute'))) {
      this.iconOnlyToggled = !this.iconOnlyToggled;
      if(this.iconOnlyToggled) {
        body.classList.add('sidebar-icon-only');
      } else {
        body.classList.remove('sidebar-icon-only');
      }
    } else {
      this.sidebarToggled = !this.sidebarToggled;
      if(this.sidebarToggled) {
        body.classList.add('sidebar-hidden');
      } else {
        body.classList.remove('sidebar-hidden');
      }
    }
  }

  // toggle right sidebar
  // toggleRightSidebar() {
  //   document.querySelector('#right-sidebar').classList.toggle('open');
  // }

}
