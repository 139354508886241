import { Component, OnInit, VERSION, ViewChild, ElementRef } from '@angular/core';

import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { BatchService } from '../../_services/batch.service';
import { environment } from '../../../environments/environment';

declare var showAssignUsersPopup: Function;
declare var hideAssignUsersPopup: Function;
declare var showAssignTeachersPopup: Function;
declare var hideAssignTeachersPopup: Function;
declare var checkTosterResponse: Function;

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})

export class CreateUpdateComponent implements OnInit {
  
  public environment = environment;

  @ViewChild("dateSelection") dateSelection: ElementRef;
  @ViewChild("dateSelectiontodate") dateSelectiontodate: ElementRef;
  public teachers; 
  public startAt="";
  public todate="";
  public courses;
  public students; 
  public studentsData; 
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false; 
  tempStudents = [];
  tempStudentsName = [];
  tempTeachers = [];
  tempTeachersName = [];
  public courseId='';
  public firstName='';
  public age='';
  public mobile='';
  public tFirstName='';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _BatchService: BatchService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {    
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id; 
    this.getAllTeachers();
    //this.getStudents();
     this.getAllCourses(); 
    this.form = this.formBuilder.group({
      name: ['', Validators.required], 
      teachers: ['', Validators.required],
      course: ['', Validators.required],
      students:['', Validators.required],
      isActive: true,  
     },{
      }); 
    if (!this.isAddMode) {
      this._BatchService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){  

              // Teachers
              const teacherArr = [];                 
              let teacherIds = response.data.teachers;
              teacherIds.forEach(ids => {
                teacherArr.push(ids._id);  
                this.tempTeachers.push(ids._id);                  
                this.tempTeachersName.push(ids.firstName);                 
              });

              // Students
              const studentArr = [];                 
              let studentIds = response.data.students;
              studentIds.forEach(cId => {
                studentArr.push(cId._id);                  
                this.tempStudents.push(cId._id);                  
                this.tempStudentsName.push(cId.firstName);                  
              });                         
              //this.fetchStudentByCourse(response.data.course._id);
              this.form.patchValue(response.data);
              this.courseId = response.data.course._id;
              this.filterStudentByCourse(response.data.course._id);
              this.form.patchValue({course: response.data.course._id});
              this.form.patchValue({teachers: teacherArr});
              this.form.patchValue({students: studentArr});                
            } else {
              this._AlertService.error(response.message);
            }    
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }
 
  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.name == '') {
      this._AlertService.error('Please enter the batch name.');   
      return false;
    }
    if (this.form.value.course == '') {
      this._AlertService.error('Please select course.');   
      return false;
    }
    if (this.form.value.teachers == '') {
      this._AlertService.error('Please select teachers.');   
      return false;
    }
    if (this.form.value.students == '') {
      this._AlertService.error('Please select students.');   
      return false;
    } 
    this.submitted = true;
    this.spinner.show();
    if (this.isAddMode) {
      this.create();
    } else {       
      this.update();
    }
  }

  private create() {          
    this._BatchService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }  
  
  private update() {
    this._BatchService.update(this.id, this.form.value).pipe(first())
    .subscribe({
      next: (response: any) => {
        if(response.status==200){
          this._AlertService.success(response.message);
          this._location.back();
        } else {
          this._AlertService.error(response.message);
        }  
        this.spinner.hide();
      },
      error: error => {
        this.spinner.hide();
      }
    });
  }

 /* getStudents() {
		this.spinner.show();
		this._BatchService.getStudentsForAssign().subscribe(
		  (response: any)  => {
			if(response.status==200){
			  this.students = response.data; 
			} else {
			  this._AlertService.error(response.message);
			}
			 this.spinner.hide();
		  },
		  err =>  {this.spinner.hide();},
		  () => this.spinner.hide()
		);
	} */
   
  /*fetchStudentByCourse(courseId) {
    this.spinner.show();
    this._BatchService.getStudentsForAssign(courseId).subscribe(
      (response: any)  => {
      if(response.status==200){
        this.students = response.data; 
      } else {
        this._AlertService.error(response.message);
      }
       this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }  */

  getAllTeachers() {
    this.spinner.show();
    this._BatchService.getTeachers().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.teachers = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 
 
  getAllCourses(){
    this.spinner.show();
    this._BatchService.getCourses().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.courses = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  getStudentsByfilter() {
    this.spinner.show();
    let searchData = {
      startAt:this.startAt,
      toDate:this.todate,
      age:this.age,
      firstName:this.firstName,
      mobile:this.mobile,
      courseId:this.courseId,
    };
    this._BatchService.getStudentsForAssign(searchData).subscribe(
      (response: any)  => {
      if(response.status==200){
        this.studentsData = response.data; 
      } else {
        this._AlertService.error(response.message);
      }
       this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }
  
  assignStudents() {
    if(!this.courseId) {
      alert("Please select course first");
      //checkTosterResponse("Please select course first","Error");
      return false;    
    } else {
      showAssignUsersPopup();
      this.getStudentsByfilter();
    }
  }

  assignTeachers() {
    showAssignTeachersPopup();
    this.getAllTeachers();
  }
  
  filterStudentByCourse(courseId) {
    this.courseId = courseId;
    this.getStudentsByfilter();
  }

  onFilterByName(firstName) {
    this.firstName = firstName;
    this.getStudentsByfilter();
  }

  onFilterByStudentAge(age) {
    this.age = age;
    this.getStudentsByfilter();
  }

  onFilterByMobile(mobile) {
    this.mobile = mobile;
    this.getStudentsByfilter();
  }

  onTeacherFilterByName(tFirstName) {
    this.tFirstName = tFirstName;
    this.getAllTeachers();
  }

  updateStudents() {
    this.form.patchValue({students: this.tempStudents}); 
    hideAssignUsersPopup();
  }

  updateTeachers() {
    this.form.patchValue({teachers: this.tempTeachers}); 
    hideAssignTeachersPopup();
  }

  cancelStudentPopup() {
    hideAssignUsersPopup();
  }

  cancelTeacherPopup() {
    hideAssignTeachersPopup();
  }

  onChangeUpdateStdTemp(id:string, isChecked: boolean, firstName:string) {
    if(isChecked) {
      this.tempStudents.push(id);
      this.tempStudentsName.push(firstName);
    } else {
      let index = this.tempStudents.findIndex(x => x == id)
      this.tempStudents.splice(index, 1);
      this.tempStudentsName.splice(index, 1);
    }
  }

  onChangeUpdateTeacherTemp(id:string, isChecked: boolean, firstName:string) {
    if(isChecked) {
      this.tempTeachers.push(id);
      this.tempTeachersName.push(firstName);
    } else {
      let index = this.tempTeachers.findIndex(x => x == id)
      this.tempTeachers.splice(index, 1);
      this.tempTeachersName.splice(index, 1);
    }
  }

  hasProp(id, key) {
    let index = this.tempStudents.findIndex(x => x == id)
    if(key == "checked") {
      if(index == -1) {
        return false;
      } else {
        return true;
      } 
    } else {
      if(index == -1) {
        return true;
      } else {
        return false;
      }
    }    
  }

  hasPropTeacher(id, key) {
    let index = this.tempTeachers.findIndex(x => x == id)
    if(key == "checked") {
      if(index == -1) {
        return false;
      } else {
        return true;
      } 
    } else {
      if(index == -1) {
        return true;
      } else {
        return false;
      }
    }    
  }


  searchDataByFilters() {         
    this.startAt = this.dateSelection.nativeElement.value;
    this.getStudentsByfilter();
  }

  searchDataByFiltersTodate() {  
    //alert(this.dateSelectiontodate.nativeElement.value);
    this.todate = this.dateSelectiontodate.nativeElement.value;
    this.getStudentsByfilter();
  }
  


}