import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AlertModule } from './_alert';
import { NgxSpinnerModule } from "ngx-spinner";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';


import { CategoriesModule } from './categories/categories.module';
import { RolesModule } from './roles/roles.module';
import { CoursesModule } from './courses/courses.module';
import { StudentsModule } from './students/students.module';
import { TeachersModule } from './teachers/teachers.module';
import { UsersModule } from './users/users.module';
import { LibrariesModule } from './libraries/libraries.module';
import { BatchesModule } from './batches/batches.module';
import { ClassesModule } from './classes/classes.module';
import { BannersModule } from './banners/banners.module';
import { BlogsModule } from './blogs/blogs.module';

import { BookingsModule } from './bookings/bookings.module';
import { DoubtsModule } from './doubts/doubts.module';
import { TasksModule } from './tasks/tasks.module';
import { ContactusModule } from './contactus/contactus.module';
import { TransactionsModule } from './transactions/transactions.module';
import { CmspagesModule } from './cmspages/cmspages.module';
import { QuestionsModule } from './questions/questions.module';
import { TestsModule } from './tests/tests.module';
import { TestReportModule } from './test-report/test-report.module';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './user-pages/login/login.component';
import { ForgotPasswordComponent } from './user-pages/forgot-password/forgot-password.component';
import { UpdatePasswordComponent } from './user-pages/change-password/change-password.component';
import { ResetPasswordComponent } from './user-pages/reset-password/reset-password.component';
import { AppComponent } from './app.component';
import { TodoComponent } from './apps/todo-list/todo/todo.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { TodoListComponent } from './apps/todo-list/todo-list.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    UpdatePasswordComponent,
    TodoListComponent,
    TodoComponent,
    SpinnerComponent,
    ContentAnimateDirective,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    AngularMultiSelectModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    AlertModule,
    NgxSpinnerModule,
    CategoriesModule,
    RolesModule,
    ClassesModule,
    BannersModule,
    BlogsModule,
    BookingsModule,
    DoubtsModule,
    TasksModule,
    ContactusModule,
    TransactionsModule,
    CmspagesModule,
    CoursesModule,
    LibrariesModule,
    StudentsModule,
    TeachersModule,
    UsersModule,
    QuestionsModule,
    TestsModule,
    BatchesModule,
    TestReportModule,
  ],
  providers: [ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
