import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {UserService} from '../../_services/user.service';
import { AuthService } from '../../_services/auth.service';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;

  constructor(
    private _UserService: UserService,
    private _AuthService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private spinner: NgxSpinnerService
  ) { 
    
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public doLogin() {  
    //this.loginForm.get('username').value
    let data = this.loginForm.value;

    //validate
    if(this.loginForm.value.username==''){
      this._AlertService.error('Username required.');
      return
    }

    if(this.loginForm.value.password==''){
      this._AlertService.error('password required.');
      return
    }

    this.spinner.show();
  
    // Submit request to API
    this._UserService
      .login(data)
      .subscribe(
        (response: any) => {
          if(response.status==200){
            this._AuthService.setAuth(response.data);
            this.router.navigate(['/dashboard']);
          } else {
            this._AlertService.error(response.message);
          }
        },
        (error) => {
          this.spinner.hide();
        }, () => this.spinner.hide()
      );
  }

}
