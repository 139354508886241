import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  form: FormGroup;
  isAddMode: boolean;
  submitted = false;
  public token="";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _UserService: UserService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void { 
    this.token = this.route.snapshot.queryParams['token']; 
    this.form = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required], 
    }, {

      });
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {    
    this.token = this.route.snapshot.queryParams['token'];
    let data = this.form.value;
    if (this.form.value.newPassword == '') {       
      this._AlertService.error('Please enter new password.'); 
      return false;
    }

    if (this.form.value.confirmPassword == '') {
       this._AlertService.error('Please enter confirm password.'); 
      return false;
    } 

    if(this.form.value.newPassword != this.form.value.confirmPassword ){
      this._AlertService.error("Confirm password didn't matched with the new password."); 
      return false;
    } 

    this.spinner.show(); 

    let reqData = {
      token:this.token,
      newPassword: this.form.value.newPassword,
      confirmPassword:this.form.value.confirmPassword,
    } 
  
    // Submit request to API
    this._UserService
      .resetpassword(reqData)
      .subscribe(
        (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this.router.navigate(['/user/login']);
          } else {
           // this._AlertService.error(response.message);
            this.router.navigate(['/user/login']);
          }
        },
        (error) => {
          this.spinner.hide();
        }, () => this.spinner.hide()
      );

  } 
 
}
