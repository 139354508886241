import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BannersRoutingModule } from './banners-routing.module';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { DeleteComponent } from './delete/delete.component';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [IndexComponent, ViewComponent, DeleteComponent, CreateUpdateComponent],
  imports: [
    CommonModule,
    BannersRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule
  ]
})
export class BannersModule { }
