import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

import { AlertService } from '../../_alert';
import { TestService } from '../../_services/tests.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  //selector: 'app-delete',
  //templateUrl: './delete.component.html',
  //styleUrls: ['./delete.component.scss']
})

export class AddQuestionsComponent implements OnInit {
  id: string;
  queId: string;
  testId : string;

  constructor(
    private _AlertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private _TestService: TestService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void { 
    this.id = this.route.snapshot.params['id'];
    this.queId = this.id.split(',')[0];
    this.testId = this.id.split(',')[1];
     if(window.confirm('Are you sure you want add this question ?'))
      this.addQuestion();
    else
      this._location.back();
  }

  addQuestion() {
    this.spinner.show();
     let reqdata = {
      queId:this.queId,
      testId:this.testId
    };
    this._TestService.addQuestionInTest(reqdata).subscribe(
      (response: any)  => {
        if(response.status==200){
          this._AlertService.success(response.message);
          this._location.back();
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

}
