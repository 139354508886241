import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { TestService } from '../../_services/tests.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})

export class ViewComponent implements OnInit {
  testId: string;
  questions: {};
  public studentTestDetails : {};
  public studentId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _TestService: TestService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.testId = this.route.snapshot.params['testId'];
    this.studentId = this.route.snapshot.params['studentId'];
    this._TestService.getTestReportByStudent(this.testId, this.studentId)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){ 

              // console.log(response.data); return false;

              this.questions = response.data.questions; 
              this.studentTestDetails = response.data.studentTestDetails;
            } else {
              this._AlertService.error(response.message);
            } 
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
  }

}
