import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

  form: FormGroup;
  isAddMode: boolean;
  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _UserService: UserService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required], 
    }, {

      });
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.oldPassword == '') {       
      this._AlertService.error('Please enter old password.'); 
      return false;
    }

    if (this.form.value.newPassword == '') {       
      this._AlertService.error('Please enter new password.'); 
      return false;
    }

    if (this.form.value.confirmPassword == '') {
       this._AlertService.error('Please enter confirm password.'); 
      return false;
    } 

    if(this.form.value.newPassword != this.form.value.confirmPassword ){
      this._AlertService.error("Confirm password didn't matched with the new password."); 
      return false;
    }

    this.submitted = true;
    this.spinner.show();
    this.update();
  } 

  private update() {
    let data = {
      old_password: this.form.value.oldPassword,
      new_password: this.form.value.newPassword
    }

    this._UserService.updatePassword(data)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }
}
