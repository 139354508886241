import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LibrariesRoutingModule } from './libraries-routing.module';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { DeleteComponent } from './delete/delete.component';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [IndexComponent, ViewComponent, DeleteComponent, CreateUpdateComponent],
  imports: [
    CommonModule,
    LibrariesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ]
})
export class LibrariesModule { }
