import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class BannersService {

  constructor(private _ApiService: ApiService) { }

  getAll() {
    return this._ApiService.get('banners');
  }

  getById(id) {
    return this._ApiService.get('banners/'+ id);
  }

  create(data) {
      return this._ApiService.post('banners', data);
  }

  update(id, data) {
      return this._ApiService.put('banners/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('banners/' + id);
  }
}
