import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchData) {
    return this._ApiService.post('librarieslist', searchData);
  }

  getById(id) {
    return this._ApiService.get('libraries/'+ id);
  }

  create(data) {
    return this._ApiService.post('libraries', data);
  }

  update(id, data) {
    return this._ApiService.put('libraries/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('libraries/' + id);
  }

  getAllCourses(data = Array){
    return this._ApiService.post('libraries/getactivecourses', data);
  }
  getAllActiveBatch(data = Array){
    return this._ApiService.post('libraries/getAllActiveBatch', data);
  }

  getAllActiveBatchByCourse(data){
    return this._ApiService.post('libraries/getAllActiveBatchByCourse', data);
  }

}