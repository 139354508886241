import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BatchesRoutingModule } from './batches-routing.module';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { DeleteComponent } from './delete/delete.component';
import { ViewStudentComponent } from './add-students/view-student.component';

import { CreateUpdateComponent } from './create-update/create-update.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [IndexComponent, ViewComponent, DeleteComponent, CreateUpdateComponent, ViewStudentComponent],
  imports: [
    CommonModule,
    AngularMultiSelectModule,
    BatchesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class BatchesModule { }
