import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_alert';
import { BookingsService } from '../../_services/bookings.service';
import { BatchService } from '../../_services/batch.service';
import { NgxSpinnerService } from "ngx-spinner";
import { StudentService } from '../../_services/student.service';
import { LiveClassService } from '../../_services/live-class.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public environment = environment; 

  public bookings;
  public page=1;
  public fileName;
  public filePath;
  public totalBookings;
  public pageSize;
  public currentPage=1;
  public courseId='';
  public bookingType='';
  public batchId='';
  public classId='';
  public studentId='';
  public courses;
  public students;
  dropdownBatches = [];
  dropdownClasses = [];

  constructor(
    private _AlertService: AlertService,
    private _BookingsService: BookingsService,
    private _LiveClassService: LiveClassService,
    private _BatchService: BatchService,
    private _StudentService: StudentService,
    private spinner: NgxSpinnerService
    
  ) { }

  ngOnInit(): void {
    this.getBookings();
    this.getAllCourses(); 
    this.getAllStudents(); 
    this.getAllClasses();
    this.getActiveBatches();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getBookings();
    }
  }

  getBookings() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      courseId:this.courseId,
      classId:this.classId,
      bookingType:this.bookingType,
      batchId:this.batchId,
      studentId:this.studentId,
    };
    this._BookingsService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.bookings = response.data; 
          this.page = response.pageNo; 
          this.totalBookings = response.totalRecord; 
          this.pageSize = response.pageSize;          
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 
 
  onFilterByCourse(key){
    if (key==''){
      this.courseId = '';
    } else {
      this.courseId = key;
    }
    this.getBookings();
  } 

  onFilterByStudent(key){
    if (key==''){
      this.studentId = '';
    } else {
      this.studentId = key;
    }
    this.getBookings();
  } 
  
  getAllCourses(){
    this.spinner.show();
    this._BatchService.getCourses().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.courses = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  getAllStudents(){
    this.spinner.show();
    this._StudentService.getAll().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.students = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onFilterByBookingType(key){
    if (key==''){
      this.bookingType = '';
    } else {
      this.bookingType = key;
    }
    this.getBookings();
  } 
  
  getActiveBatches() {
    this.spinner.show();
    this._BatchService.getActiveBatches().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownBatches = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onFilterByBatch(key){
    if (key==''){
      this.batchId = '';
    } else {
      this.batchId = key;
    }
    this.getBookings();
  }

  onFilterByClass(key){
    if (key==''){
      this.classId = '';
    } else {
      this.classId = key;
    }
    this.getBookings();
  }

  getAllClasses() {
    this.spinner.show();
    this._LiveClassService.getAllClasses().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownClasses = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  exportDataByFilters() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      courseId:this.courseId,
      classId:this.classId,
      bookingType:this.bookingType,
      batchId:this.batchId,
      studentId:this.studentId,
    };
    this._BookingsService.exportDataByFilters(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){  
          this.fileName = response.data;
          this.filePath = environment.apiUrl+'/uploads/'+this.fileName;
          window.open(this.filePath);
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 


  
}
