import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('staticpageslist', searchParam);
  }

  getById(id) {
    return this._ApiService.get('staticpages/'+ id);
  }

  update(id, data) {
      return this._ApiService.put('staticpages/' + id, data);
  }

  create(data) {
      return this._ApiService.post('staticpages/create', data);
  }


}