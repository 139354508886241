import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { TasksService } from '../../_services/tasks.service';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})

export class ViewComponent implements OnInit {
  public environment = environment;
  id: string;
  details: [];
  descriptionContent:any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _TasksService: TasksService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this._TasksService.getById(this.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if(response.status==200){
            this.details = response.data;
            this.descriptionContent = this.sanitizer.bypassSecurityTrustHtml(response.data.description); 
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }


}