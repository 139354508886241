import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_alert';
import { UserService } from '../../_services/user.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {

  public users;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public isActive='';
  public firstName='';
  constructor(
    private _AlertService: AlertService,
    private _UserService: UserService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getUsers();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getUsers();
    }
  }

  getUsers() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      isActive : this.isActive,
      firstName : this.firstName,
    };
    this._UserService.getAllUsers(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.users = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          this._AlertService.error(response.message);
        }

         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getUsers();
  } 

  onFilterByName(key){
    if (key==''){
      this.firstName = '';
    } else {
      this.firstName = key;
    }
    this.getUsers();
  } 

}
