import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(private _ApiService: ApiService) { }

  getAll() {
    return this._ApiService.get('courses');
  }
   

  getAllList(searchParam) {
    return this._ApiService.post('courselist', searchParam);
  }

  getById(id) {
    return this._ApiService.get('courses/'+ id);
  }

  create(data) {
      return this._ApiService.post('courses', data);
  }

  update(id, data) {
      return this._ApiService.put('courses/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('courses/' + id);
  }


  getAllCategories(data = Array) {
    return this._ApiService.post('courses/activecategories', data);
  }
}
