import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { SubtasksService } from '../../_services/subtasks.service';

@Component({
  selector: 'app-subtasks',
  templateUrl: './subtasks.component.html',
  styleUrls: ['./subtasks.component.scss']
})

export class SubtasksComponent implements OnInit {

  id: string;
  subtasksData: [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _SubtasksService: SubtasksService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this._SubtasksService.getSubtasksList(this.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if(response.status==200){
            this.subtasksData = response.data;
          } else {
            this._AlertService.error(response.message);
          }  
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }


}