import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_alert';
import { RolesService } from '../../_services/roles.service';
import { AuthService } from '../../_services/auth.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public roles;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public isActive = '';
  public name = '';
  public route_urls = [];
  public is_superadmin = false;

  constructor(
    private _AlertService: AlertService,
    private _RolesService: RolesService,
    private _AuthService: AuthService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getRoles();

     let User = this._AuthService.getUserInfo();
     this.is_superadmin = User.is_superadmin;
     //if user is superadmin
     if(!this.is_superadmin){
       let permissionUrl = this._AuthService.getRouteUrl();
       this.route_urls = permissionUrl.split(',');
     }
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getRoles();
    }
  }

  getRoles() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      isActive:this.isActive,
      name:this.name,
    };
    this._RolesService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.roles = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getRoles();
  } 

  onFilterByName(key){
    if (key==''){
      this.name = '';
    } else {
      this.name = key;
    }
    this.getRoles();
  } 

}
