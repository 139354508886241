import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class BookingsService {

  constructor(private _ApiService: ApiService) { }

  create(data) {
      return this._ApiService.post('bookings/create', data);
  }

  delete(id) {
      return this._ApiService.delete('bookings/' + id);
  }

  bookingsByStudent(studentId) {
      return this._ApiService.get('bookings/bookingsByStudent/' + studentId);
  }
  
  getAll(searchParams) {
    return this._ApiService.post('bookings/list', searchParams);
  }

  exportDataByFilters(searchParam) {
    return this._ApiService.post('bookings/exportDataByFilters', searchParam);
  }

}