import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class ContactusService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('contactuslist', searchParam);
  }

  getById(id) {
    console.log(id);
    return this._ApiService.get('contactus/'+ id);
  }

  delete(id) {
      return this._ApiService.delete('contactus/' + id);
  }


}
