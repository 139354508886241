import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class SubtasksService {

  constructor(private _ApiService: ApiService) { }

  getSubtasksList(taskId) {
    return this._ApiService.get('subtasks/subtaskslist/'+taskId);
  }

  getSingleRecordById(subtaskId) {
    return this._ApiService.get('subtasks/details/'+subtaskId);
  }

  update(id, data) {
      return this._ApiService.put('subtasks/update/' + id, data);
  }
  
}
