import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class DoutService {

  constructor(private _ApiService: ApiService) { }

  getDoutsByFilter(data) {
    return this._ApiService.post('douts/getDoutsByFilter', data);
  }

  getById(id) {
    return this._ApiService.get('douts/'+ id);
  }

  getDoutsByTask(taskId) {
    return this._ApiService.get('douts/doutsByTask/'+taskId);
  }

}
