import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateGuard } from "./_guard/private.guard";
import { GuestGuard } from "./_guard/guest.guard";

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './user-pages/login/login.component';
import { ForgotPasswordComponent } from './user-pages/forgot-password/forgot-password.component';
import { LogoutComponent } from './user-pages/logout.component';
import { UpdatePasswordComponent } from './user-pages/change-password/change-password.component';
import { ResetPasswordComponent } from './user-pages/reset-password/reset-password.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [PrivateGuard] },
  { path: 'user/login', component: LoginComponent, canActivate: [GuestGuard] },
  { path: 'user/forgot-password', component: ForgotPasswordComponent, canActivate: [GuestGuard] },
  { path: 'user/logout', component: LogoutComponent, canActivate: [PrivateGuard] },
  { path: 'user/update-password', component: UpdatePasswordComponent, canActivate: [PrivateGuard] },
  { path: 'user/reset-password', component: ResetPasswordComponent, canActivate: [GuestGuard] },

  { path: 'categories', loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule) },
  { path: 'roles', loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule) },
  { path: 'permission', loadChildren: () => import('./permission/permission.module').then(m => m.PermissionModule) },
  { path: 'courses', loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule) },
  { path: 'banners', loadChildren: () => import('./banners/banners.module').then(m => m.BannersModule) },
  { path: 'blogs', loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule) },
  { path: 'landing-pages', loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule) },
  { path: 'landing-pages-1', loadChildren: () => import('./landing-page-1/landing-page.module').then(m => m.LandingPageModule) },

  { path: 'testimonial', loadChildren: () => import('./testimonial/testimonial.module').then(m => m.TestimonialModule) },
  { path: 'medianews', loadChildren: () => import('./medianews/medianews.module').then(m => m.MedianewsModule) },
  { path: 'team', loadChildren: () => import('./team/team.module').then(m => m.TeamModule) },
  { path: 'students', loadChildren: () => import('./students/students.module').then(m => m.StudentsModule) },
  { path: 'teachers', loadChildren: () => import('./teachers/teachers.module').then(m => m.TeachersModule) },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
  { path: 'libraries', loadChildren: () => import('./libraries/libraries.module').then(m => m.LibrariesModule) },
  { path: 'batches', loadChildren: () => import('./batches/batches.module').then(m => m.BatchesModule) },
  { path: 'classes', loadChildren: () => import('./classes/classes.module').then(m => m.ClassesModule) },
  { path: 'bookings', loadChildren: () => import('./bookings/bookings.module').then(m => m.BookingsModule) },
  { path: 'doubts', loadChildren: () => import('./doubts/doubts.module').then(m => m.DoubtsModule) },
  { path: 'tasks', loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule) },
  { path: 'contactus', loadChildren: () => import('./contactus/contactus.module').then(m => m.ContactusModule) },
  { path: 'transactions', loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule) },
  { path: 'cmspages', loadChildren: () => import('./cmspages/cmspages.module').then(m => m.CmspagesModule) },
  { path: 'questions', loadChildren: () => import('./questions/questions.module').then(m => m.QuestionsModule) },
  { path: 'tests', loadChildren: () => import('./tests/tests.module').then(m => m.TestsModule) },
  { path: 'test-report', loadChildren: () => import('./test-report/test-report.module').then(m => m.TestReportModule) },


  /*
  { path: 'user', loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule)},
  { path: 'basic-ui', loadChildren: () => import('./basic-ui/basic-ui.module').then(m => m.BasicUiModule) },
  { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartsDemoModule) },
  { path: 'forms', loadChildren: () => import('./forms/form.module').then(m => m.FormModule) },
  { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
  { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
  { path: 'general-pages', loadChildren: () => import('./general-pages/general-pages.module').then(m => m.GeneralPagesModule) },
  { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },*/
  { path: 'error', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: '**', redirectTo: '/error/404' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
