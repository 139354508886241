import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateGuard } from "../_guard/private.guard";

import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { ViewdoutsComponent } from './viewdouts/viewdouts.component';
import { SubtasksComponent } from './subtasks/subtasks.component';
import { ViewSubtasksComponent } from './view-subtasks/view-subtasks.component';

const routes: Routes = [
  { path: '', redirectTo: 'tasks/index', pathMatch: 'full'},
  { path: 'index', component: IndexComponent, canActivate: [PrivateGuard] },
  { path: 'view/:id', component: ViewComponent, canActivate: [PrivateGuard] },
  { path: 'subtasks/:id', component: SubtasksComponent, canActivate: [PrivateGuard] },
  { path: 'view-subtasks/:id', component: ViewSubtasksComponent, canActivate: [PrivateGuard] },
  { path: 'view-douts/:id', component: ViewdoutsComponent, canActivate: [PrivateGuard] },
  { path: '', component: IndexComponent, canActivate: [PrivateGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksRoutingModule { }
