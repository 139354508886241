import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class LiveClassService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParams) {
    return this._ApiService.post('classeslist', searchParams);
  }

  getById(id) {
    return this._ApiService.get('classes/'+ id);
  }

  create(data) {
      return this._ApiService.post('classes', data);
  }

  update(id, data) {
      return this._ApiService.put('classes/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('classes/' + id);
  }

  getAllClasses() {
    return this._ApiService.get('classes');
  }

  getAllClassByBatch(batch){
    return this._ApiService.get('getclassbybatch/' + batch);
  }

}
