import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { LibraryService } from '../../_services/library.service';

declare var showAssignCoursesPopup: Function;
declare var hideAssignCoursesPopup: Function;
declare var showAssignBatchesPopup: Function;
declare var hideAssignBatchesPopup: Function;

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})

export class CreateUpdateComponent implements OnInit {
  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  public coursesData;
  public batchData;
  tempCourses = [];
  tempCoursesName = [];
  tempBatches = [];
  tempBatchesName = [];
 
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _LibraryService: LibraryService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    //this.getCourses();
    this.form = this.formBuilder.group({
      docTitle: ['', Validators.required],
      courses: ['', Validators.required],
      freeOrPaid: ['', Validators.required],
      batches: ['', Validators.required],
      docType: '',
      file: [],
      thumbnail: [],
      thumbnail_status:0,
      isActive: true
    }, {
      });

    if (!this.isAddMode) {
      this._LibraryService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){

               // Batch
                const batchArr = [];                 
                let batchIds = response.data.batches;
                batchIds.forEach(ids => {
                  batchArr.push(ids._id);                  
                  this.tempBatches.push(ids._id);                  
                  this.tempBatchesName.push(ids.name);  
                });

                // Course
                const courseArr = [];                 
                let courseIds = response.data.courses;
                courseIds.forEach(cId => {
                  courseArr.push(cId._id);    
                  this.tempCourses.push(cId._id);                  
                  this.tempCoursesName.push(cId.courseTitle);              
                });       
               this.form.patchValue(response.data);  
               this.form.patchValue({batches: batchArr});
               this.form.patchValue({courses: courseArr});                

            } else {
              this._AlertService.error(response.message);
            }
    
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
    //this.getAllActiveBatch();
  }
/**
 * Get All Active classes
 **/
  /*getAllActiveBatch(){
     
    this.spinner.show();
    this._LibraryService.getAllActiveBatch().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.batches = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    ); 
  }*/

  /*getCourses(){ 
    this.spinner.show();
    this._LibraryService.getAllCourses().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.courses = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );  
  }*/

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.docTitle == '') {
      this._AlertService.error('Please enter the library title.');  
      return false;
    }
    if (this.form.value.courses == '') {
      this._AlertService.error('Please select the courses.');  
      return false;
    }
    if (this.form.value.batches == '') {
      this._AlertService.error('Please select the batches.');  
      return false;
    }

    
    if (this.form.value.fileType == '') {
       this._AlertService.error('Please select the library file.');  
      return false;
    } 

    if (this.isAddMode) {
      if(this.form.value.docType == "PDF" && this.form.value.file !=""){
        var fileInput = this.form.value.file.name;
         if(fileInput.split('.').pop().toUpperCase() == "PDF"){
           // alert('OK');
         }else{
          this._AlertService.error('Please select valid PDF file.');  
          return false;
         } 
      }

      if(this.form.value.docType == "DOC" && this.form.value.file !=""){
        var fileInput = this.form.value.file.name;
         if(fileInput.split('.').pop().toUpperCase() == "DOC" || fileInput.split('.').pop().toUpperCase() == "DOCX"){
           // alert('OK');
         }else{
          this._AlertService.error('Please select valid Document file.');  
          return false;
         } 
      }

      if(this.form.value.docType == "Excel" && this.form.value.file !=""){
        var fileInput = this.form.value.file.name;
         if(fileInput.split('.').pop().toUpperCase() == "XLS" || fileInput.split('.').pop().toUpperCase() == "XLSX" || fileInput.split('.').pop().toUpperCase() == "XLSM" || fileInput.split('.').pop().toUpperCase() == "XLTX" || fileInput.split('.').pop().toUpperCase() == "CSV"){
          //  alert('OK');
         }else{
          this._AlertService.error('Please select valid Excel file.');  
          return false;
         } 
      }

      if(this.form.value.docType == "Video" && this.form.value.file !=""){
        var fileInput = this.form.value.file.name;
         if(fileInput.split('.').pop().toUpperCase() == "MP4"){
           // alert('OK');
         }else{
          this._AlertService.error('Please select mp4 video file.');  
          return false;
         } 
      }
    }
    this.submitted = true;
    this.spinner.show();
    if (this.isAddMode) {
      this.create();
    } else {
      this.update();
    }
  }

  onFileChange(event){
    const file = event.target.files[0];
    this.form.patchValue({file: file});
  }


  onThumbnailChange(event){
    const thumbnail = event.target.files[0];  
    this.form.patchValue({thumbnail: thumbnail});
    
  } 

  private create() {
    this._LibraryService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  private update() { 

    this._LibraryService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  fetchBatchesByCourse(){
    alert();
  }

  assignCourses() {
    showAssignCoursesPopup();
    this.getAllCourses();
  }

  getAllCourses(){ 
    this.spinner.show();
    this._LibraryService.getAllCourses().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.coursesData = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );  
  }

  hasPropCourse(id, key) {
    let index = this.tempCourses.findIndex(x => x == id)
    if(key == "checked") {
      if(index == -1) {
        return false;
      } else {
        return true;
      } 
    } else {
      if(index == -1) {
        return true;
      } else {
        return false;
      }
    }    
  }

  onChangeUpdateCourseTemp(id:string, isChecked: boolean, courseTitle:string) {
    if(isChecked) {
      this.tempCourses.push(id);
      this.tempCoursesName.push(courseTitle);
    } else {
      let index = this.tempCourses.findIndex(x => x == id)
      this.tempCourses.splice(index, 1);
      this.tempCoursesName.splice(index, 1);
    }
  }

  updateCourses() {
    this.form.patchValue({courses: this.tempCourses}); 
    hideAssignCoursesPopup();
  }

  cancelCoursePopup() {
    hideAssignCoursesPopup();
  }

  assignBatches() {
    if(this.tempCourses.length > 0) {
      showAssignBatchesPopup();
      this.getAllBatch(); 
    } else {
      alert("Please select course first");
      return false;   
    } 
  }

  getAllBatch(){
    this.spinner.show();
    let data = {
      tempCourses : this.tempCourses,
    };
    this._LibraryService.getAllActiveBatchByCourse(data).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.batchData = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    ); 
  }

  hasPropBatch(id, key) {
    let index = this.tempBatches.findIndex(x => x == id)
    if(key == "checked") {
      if(index == -1) {
        return false;
      } else {
        return true;
      } 
    } else {
      if(index == -1) {
        return true;
      } else {
        return false;
      }
    }    
  }

  onChangeUpdateBatchTemp(id:string, isChecked: boolean, name:string) {
    if(isChecked) {
      this.tempBatches.push(id);
      this.tempBatchesName.push(name);
    } else {
      let index = this.tempBatches.findIndex(x => x == id)
      this.tempBatches.splice(index, 1);
      this.tempBatchesName.splice(index, 1);
    }
  }

  updateBatch() {
    this.form.patchValue({batches: this.tempBatches}); 
    hideAssignBatchesPopup();
  }

  cancelBatchPopup() {
    hideAssignBatchesPopup();
  }

}