import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_alert';
import { TestService } from '../../_services/tests.service';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public tests;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public isActive = '';
  public title = '';
  constructor(
    private _AlertService: AlertService,
    private _TestService: TestService,
    private spinner: NgxSpinnerService
  ) { }
  ngOnInit(): void {
    this.getAll();
  }
  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getAll();
    }
  }
  getAll() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      isActive:this.isActive,
      title:this.title,
    };
    this._TestService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){ 

         // console.log(response.data); return false;
          this.tests = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }


  
  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getAll();
  } 
  onFilterByName(key){
    if (key==''){
      this.title = '';
    } else {
      this.title = key;
    }
    this.getAll();
  } 
}
