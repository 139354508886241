import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from './_alert';
import { AuthService } from './_services/auth.service';

const API_URL = environment.apiUrl + '/';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpOptions: {}

  constructor(
    private http: HttpClient, 
    private _AuthService: AuthService, 
    private _AlertService: AlertService,
    private router: Router) { 
  }

  setHttpOptions(){
    this.httpOptions = {
      headers: new HttpHeaders(
        { 
          'Accept': 'application/json',
          'x_rest_username': 'admin@restuser',
          'x_rest_password': 'admin@Access',
          'x_rest_version': 'v1',
          'device': 'web',
          'language': 'en',
          Authorization: 'Bearer ' + this._AuthService.getAuthToken()
        }
      )
    }
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown Error!';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error: ${error.message}`;
    }

    this._AlertService.error(errorMessage);
    return throwError(error);
  }

  get(apiName) {
    const self = this;
    this.setHttpOptions();
    return this.http.get(API_URL + apiName, this.httpOptions).pipe(catchError(error => {
      if (error.status === 401 || error.status === 403) {
        this._AuthService.removeAuth()
        this.router.navigate(['/user/login']);
      }

      return self.handleError(error);
    }));
  }

  post(apiName, data) {
    const self = this;
    this.setHttpOptions();
    let formData = this.toFormData(data);
    return this.http.post(API_URL + apiName, formData, this.httpOptions).pipe(catchError(error => {
      if (error.status === 401 || error.status === 403) {
        this._AuthService.removeAuth()
        this.router.navigate(['/user/login']);
      }

      return self.handleError(error);
    }));
  }

  put(apiName, data) {
    const self = this;
    this.setHttpOptions();
    let formData = this.toFormData(data);
    return this.http.put(API_URL + apiName, formData, this.httpOptions).pipe(catchError(error => {
      if (error.status === 401 || error.status === 403) {
        this._AuthService.removeAuth()
        this.router.navigate(['/user/login']);
      }

      return self.handleError(error);
    }));
  }

  delete(apiName) {
    const self = this;
    this.setHttpOptions();
    return this.http.delete(API_URL + apiName, this.httpOptions).pipe(catchError(error => {
      if (error.status === 401 || error.status === 403) {
        this._AuthService.removeAuth()
        this.router.navigate(['/user/login']);
      }

      return self.handleError(error);
    }));
  }

  toFormData(data) {
    let formData = new FormData();
    for(const name in data) {
        formData.append(name, data[name]);
    }

    return formData;
  }

}
