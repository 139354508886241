import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../../_services/user.service';
import { RolesService } from '../../_services/roles.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  public roles;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _UserService: UserService,
    private spinner: NgxSpinnerService,
    private _RolesService: RolesService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.getRoles();

    this.form = this.formBuilder.group({
      firstName: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      role: ['', Validators.required],
      password: ['', Validators.required],
      isActive: true
    }, {

      });

    if (!this.isAddMode) {
      this._UserService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.form.patchValue(response.data);
            } else {
              this._AlertService.error(response.message);
            }
    
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }

  getRoles(){ 
    this.spinner.show();
    this._RolesService.getAllRoles().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.roles = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );  
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.firstName == '') {
      this._AlertService.error('Please enter name.');  
      return false;
    } 
    if (this.form.value.email == '') {     
      this._AlertService.error('Please enter email.');  
      return false;
    }
    if (this.form.value.mobile == '') {
      this._AlertService.error('Please enter mobile.'); 
      return false;
    }  
    if (this.form.value.role == '') {
      this._AlertService.error('Please select role.'); 
      return false;
    }  
    this.submitted = true;
    this.spinner.show();
    if (this.isAddMode) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this._UserService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  private update() {
    this._UserService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }
}
