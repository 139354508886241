import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _UserService: UserService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required], 
    }, {

      });

    if (!this.isAddMode) {
      this._UserService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.form.patchValue(response.data);
            } else {
              this._AlertService.error(response.message);
            }    
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.password == '') {       
      this._AlertService.error('Please enter password.'); 
      return false;
    }
    if (this.form.value.confirmPassword == '') {
       this._AlertService.error('Please enter confirm password.'); 
      return false;
    } 
    if(this.form.value.password != this.form.value.confirmPassword ){
      this._AlertService.error('Please enter password & confirm password as same.'); 
      return false;
    }
    this.submitted = true;
    this.spinner.show();
    this.update();
  } 

  private update() {
    this._UserService.changePassword(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }
}
