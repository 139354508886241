import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_alert';
import { TestService } from '../../_services/tests.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public testReportData=[]; 
  public testId;
  public status;
  public firstName;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  constructor(
    private _AlertService: AlertService,
    private _TestService: TestService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }
  ngOnInit(): void {
    this.testId = this.route.snapshot.params['id'];
    this.status = "";
    this.getTestReport();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getTestReport();
    }
  }
   
  getTestReport() {
   // this.spinner.show(); 
    let searchData = {
      testId : this.route.snapshot.params['id'],
      currentPage:this.currentPage,
      status : this.status,
      firstName : this.firstName,
    }; 
    this._TestService.getTestReport(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){  
        // console.log(response.data); return false;
          this.testReportData = response.data;  
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }


  onFilterByStatus(key){
    if (key==''){
      this.status = '';
    } else {
      this.status = key;
    }
    this.getTestReport();
  } 

  onFilterByName(key){
    if (key==''){
      this.firstName = '';
    } else {
      this.firstName = key;
    }
    this.getTestReport();
  } 


    
}
