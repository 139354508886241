import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class BatchService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParams) {
    return this._ApiService.post('batcheslist',searchParams);
  }

  getActiveBatches() {
    return this._ApiService.get('batches');
  }
  getAllBatchByCourse(courseId){
    return this._ApiService.get('batcheslistbycourse/' + courseId);
  }

  getById(id) {
    return this._ApiService.get('batches/' + id);
  }
 
  create(data) { 
    return this._ApiService.post('batches', data);
  }

  update(id, data) {
    return this._ApiService.put('batches/' + id, data);
  }

  delete(id) {
    return this._ApiService.delete('batches/' + id);
  }

  getTeachers() {
    return this._ApiService.get('user/getAllTeachers');
  }

  getSelectedTeachers(batchId) {
    if (batchId == "") {
      return this._ApiService.get('user/getAllTeachers');
    } else {
      return this._ApiService.get('user/getSelectedTeachers/' + batchId);
    }

  }

  getTeachersByBatch(batchId='') {
    let batchIds = batchId;
    return this._ApiService.post('batches/getTeachersByBatch', {batchIds: batchIds});
  }

  getStudents() {
    return this._ApiService.get('user/getAllStudents');
  }

  getStudentsForAssign(data) {
    return this._ApiService.post('user/getStudentsForAssign',data);
  }

  getCourses() {
    return this._ApiService.get('user/getAllCourses');
  }
}
