import { Component, OnInit, VERSION, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from '../../_alert';
import { TasksService } from '../../_services/tasks.service';
import { NgxSpinnerService } from "ngx-spinner";
import { BatchService } from '../../_services/batch.service';
import { LiveClassService } from '../../_services/live-class.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
  
  @ViewChild("dateSelection") dateSelection: ElementRef;

  public tasks;
  public page=1;
  public totalTasks;
  public totalActiveRecords;
  public totalInactiveRecords;
  public pageSize;
  public currentPage=1;
  dropdownClasses = [];
  dropdownTeachers = [];
  dropdownBatches = [];
  public createdAt = '';
  public classId = '';
  public batchId = '';
  public title = '';
  public isActive = '';
  public teacherId = '';

  constructor(
    private _AlertService: AlertService,
    private _TasksService: TasksService,
    private _LiveClassService: LiveClassService,
    private _BatchService: BatchService,
    private spinner: NgxSpinnerService    
  ) { }

  ngOnInit(): void {
    this.getTasks();
    this.getAllClasses();
    this.getTeachers();
    this.getActiveBatches();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getTasks();      
    }
  }

  getTasks() {
    let data = {
      currentPage : this.currentPage,
      classId : this.classId,
      title : this.title,
      createdAt : this.createdAt,
      batchId : this.batchId,
      isActive : this.isActive,
      teacherId : this.teacherId,
    };
    this.spinner.show();
    this._TasksService.getTasksByFilter(data).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.tasks = response.data; 
          this.page = response.pageNo; 
          this.totalTasks = response.totalRecord; 
          this.pageSize = response.pageSize;          
          this.totalActiveRecords = response.totalActiveRecords;          
          this.totalInactiveRecords = response.totalInactiveRecords;          
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 
 
   getAllClasses() {
    this.spinner.show();
    this._LiveClassService.getAllClasses().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownClasses = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  getTeachers() {
    this.spinner.show();
    this._BatchService.getTeachers().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownTeachers = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 

  onFilterSelect(key){
    if (key==''){
      this.classId = '';
    } else {
      this.classId = key;
    }
    this.getTasks();
  } 

  onFilterByTeacher(key){
    if (key==''){
      this.teacherId = '';
    } else {
      this.teacherId = key;
    }
    this.getTasks();
  } 

  onFilterByTitle(key){
    if (key==''){
      this.title = '';
    } else {
      this.title = key;
    }
    this.getTasks();
  } 
  
  getActiveBatches() {
    this.spinner.show();
    this._BatchService.getActiveBatches().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownBatches = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onFilterByBatch(key){
    if (key==''){
      this.batchId = '';
    } else {
      this.batchId = key;
    }
    this.getTasks();
  }

  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getTasks();
  } 

  searchDataByFilters() {
    this.createdAt = this.dateSelection.nativeElement.value;
    this.getTasks();
  }
  
}
