import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private _ApiService: ApiService) { }

  getAll(data) {
    return this._ApiService.post('transaction/list',data);
  }

  exportDataByFilters(searchParam) {
    return this._ApiService.post('transaction/exportDataByFilters', searchParam);
  }

}