import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class TestService {

  constructor(private _ApiService: ApiService) { } 
   
  getAll(searchParam) {
    return this._ApiService.post('testlist', searchParam);
  } 

  
  getById(id) {
    return this._ApiService.get('tests/details/'+ id);
  }

  create(data) {
      return this._ApiService.post('tests', data);
  }

  update(id, data) {
    return this._ApiService.post('tests/update/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('tests/' + id);
  }

  getAllActiveBatch(data = Array){
    return this._ApiService.post('tests/getAllActiveBatch', data);
  }


  addQuestionInTest(data){
    return this._ApiService.post('tests/addQuestionInTest', data);
  }

  removeQuestionInTest(data){
    return this._ApiService.post('tests/removeQuestionInTest', data);
  }


  getTestReport(data) {

    //console.log(data);

    return this._ApiService.post('admin/test/testreportadmin/'+data.testId, data);
  }

  getTestReportByStudent(testId, studentId) {
    return this._ApiService.get('admin/test/gettestreportbystudent/'+ testId +'/'+studentId);
  }



}
