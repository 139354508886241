import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { RolesService } from '../../_services/roles.service';

@Component({
  selector: 'app-roles-permission',
  templateUrl: './roles-permission.component.html',
  styleUrls: ['./roles-permission.component.scss']
})
export class RolesPermissionComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  temRole = [];
  roleName = '';
  public roles;
  public rolePermission;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _RolesService: RolesService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.getRolesPermission();
    this.form = this.formBuilder.group({
      permissionId: this.formBuilder.array([], [Validators.required]),
      isActive: true
    }, {

      });

      this._RolesService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.roles = response.data;
              response.data.permissionId.forEach(value => {
                this.permissionId().push(new FormControl(value));
              });
              this.roleName = this.roles.name;
              this.temRole = this.roles.permissionId;
            } else {
              this._AlertService.error(response.message);
            }
    
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
  }

  onCancel() {
    this._location.back();
  }
  onSubmit() {
    if (this.form.value.permissionId.length == 0) {
      this._AlertService.error('Please select at least one.');
      return false;
    }
    this.submitted = true;
    this.spinner.show();
    this.update();
    
  }

  private update() {
    this._RolesService.updatePermission(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  getRolesPermission() {
    this._RolesService.rolesPermissionList().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.rolePermission = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  //define form array inputs
  permissionId() : FormArray {
    return this.form.get("permissionId") as FormArray
  }

  onCheckboxChange(e) {
  //const permissionId: FormArray = this.form.get('permissionId') as FormArray;
  if (e.target.checked) {
    this.permissionId().push(new FormControl(e.target.value));
  } else {
    let i: number = 0;
    this.permissionId().controls.forEach((item: FormControl) => {
      if (item.value == e.target.value) {
        this.permissionId().removeAt(i);
        return;
      }
      i++;
    });
  }
}


}
