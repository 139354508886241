import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { RolesService } from '../../_services/roles.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _RolesService: RolesService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      isActive: true
    }, {

      });

    if (!this.isAddMode) {
      this._RolesService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.form.patchValue(response.data);
            } else {
              this._AlertService.error(response.message);
            }
    
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }

  onCancel() {
    this._location.back();
  }
  onSubmit() {
    if (this.form.value.name == '') {
      // alert('Please enter name.');
      this._AlertService.error('Please enter name.');
      return false;
    }
    this.submitted = true;
    this.spinner.show();
    if (this.isAddMode) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this._RolesService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  private update() {
    this._RolesService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }
}
