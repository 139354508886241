import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class RolesService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('roleslist', searchParam);
  }

  getAllRoles() {
    return this._ApiService.get('allroles');
  }

  getById(id) {
    return this._ApiService.get('roles/'+ id);
  }

  create(data) {
      return this._ApiService.post('roles', data);
  }

  update(id, data) {
      return this._ApiService.put('roles/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('roles/' + id);
  }

  rolesPermissionList() {
    return this._ApiService.get('roles-permission');
  }

  updatePermission(id, data){
    return this._ApiService.put('roles-permission/' + id, data);
  }
}
