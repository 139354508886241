import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchParam) {
    return this._ApiService.post('categorylist', searchParam);
  }

  getById(id) {
    return this._ApiService.get('categories/'+ id);
  }

  create(data) {
      return this._ApiService.post('categories', data);
  }

  update(id, data) {
      return this._ApiService.put('categories/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('categories/' + id);
  }
}
