import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})

export class QuestionService {

  constructor(private _ApiService: ApiService) { } 
   
  getAll(searchParam) {
    return this._ApiService.post('questionslist', searchParam);
  } 
  getById(id) {
    return this._ApiService.get('questions/details/'+ id);
  }

  create(data) {
      return this._ApiService.post('questions', data);
  }

  update(id, data) {
    return this._ApiService.post('questions/update/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('questions/' + id);
  }
}
