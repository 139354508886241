import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { TestsRoutingModule } from './tests-routing.module';
import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';
import { DeleteComponent } from './delete/delete.component';
import { CreateUpdateComponent } from './create-update/create-update.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';  
import { AddRemoveQuestionsComponent } from './add-questions/add-remove-questions.component';
import { AddQuestionsComponent } from './add/add-questions.component';
import { RemoveQuestionsComponent } from './remove/remove-questions.component';



import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
export const MY_NATIVE_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};
@NgModule({
  declarations: [IndexComponent, ViewComponent, DeleteComponent, CreateUpdateComponent, AddRemoveQuestionsComponent, AddQuestionsComponent, RemoveQuestionsComponent],
  imports: [
    CommonModule,
    TestsRoutingModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,       
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ]
})
export class TestsModule { }
