import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private _ApiService: ApiService) { }

  getAll() {
    return this._ApiService.get('students');
  }

  getAllList(searchParam) {
    return this._ApiService.post('studentslist', searchParam);
  }

  exportDataByFilters(searchParam) {
    return this._ApiService.post('exportDataByFilters', searchParam);
  }
  
  getById(id) {
    return this._ApiService.get('students/details/'+ id);
  }

  create(data) { 
      return this._ApiService.post('students', data);
  }

  update(id, data) {
      return this._ApiService.post('students/update/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('students/' + id);
  }
}
