import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _ApiService: ApiService, private _AuthService: AuthService) { }

  login(data) {
    return this._ApiService.post('user/login', data);
  }

  forgotPassword(data) {
    return this._ApiService.post('user/forgot-password', data);
  }
  resetpassword(data){
    return this._ApiService.post('user/resetpassword/', data);
  }
  logout() {
    return this._AuthService.removeAuth();
  }

  getDashboard() {
    return this._ApiService.get('user/dashboard');
  }

  getAll() {
    return this._ApiService.get('user');
  }

  getById(id) {
    return this._ApiService.get('user/'+ id);
  }

  create(data) {
      return this._ApiService.post('user/create', data);
  }

  update(id, data) {
      return this._ApiService.put('user/update/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('user/' + id);
  }

  getAllUsers(searchParam) {
    return this._ApiService.post('user/list', searchParam);
  }

  changePassword(id,data){
    return this._ApiService.put('user/change-password/' + id, data);
  }

  updatePassword(data){
    return this._ApiService.put('user/update-password', data);
  }

}
