import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_alert';
import { BannersService } from '../../_services/banners.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {

  public banners;
  public environment = environment;

  constructor(
    private _AlertService: AlertService,
    private _BannersService: BannersService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getBanners();
  }

  getBanners() {
    this.spinner.show();
    this._BannersService.getAll().subscribe(
      (response: any)  => {
        if(response.status==200){
          console.log(response.data);
          this.banners = response.data; 
        } else {
          this._AlertService.error(response.message);
        }

         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }
  
}
