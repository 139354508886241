import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_alert';
import { CourseService } from '../../_services/course.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {

  public courses;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public isActive = '';
  public category = '';
  public courseTitle = '';
  public categories;

  constructor(
    private _AlertService: AlertService,
    private _CourseService: CourseService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getCourses();
    this.getCategories();
  }
  
  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getCourses();
    }
  }


  getCourses() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      isActive : this.isActive,
      category : this.category,
      courseTitle : this.courseTitle,
    };
    this._CourseService.getAllList(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.courses = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          this._AlertService.error(response.message);
        }

         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  getCategories(){ 
    this.spinner.show();
    this._CourseService.getAllCategories().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.categories = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );  
  }

  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getCourses();
  } 

  onFilterByCategory(key){
    if (key==''){
      this.category = '';
    } else {
      this.category = key;
    }
    this.getCourses();
  } 

  onFilterByTitle(key){
    if (key==''){
      this.courseTitle = '';
    } else {
      this.courseTitle = key;
    }
    this.getCourses();
  }
  
}
