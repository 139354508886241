import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { TestService } from '../../_services/tests.service';
import { QuestionService } from '../../_services/questions.service'; 
@Component({
  selector: 'app-create-update',
  templateUrl: './add-remove-questions.component.html',
  styleUrls: ['./add-remove-questions.component.scss']
})
export class AddRemoveQuestionsComponent implements OnInit {
  form: FormGroup;
  id: string;
  testId: string;
  isAddMode: boolean;
  submitted = false; 
  public questions;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public isActive = '';
  public question = '';
  public testDetails = "";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _TestService: TestService,
    private _QuestionService: QuestionService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }
  ngOnInit(): void {
      this.getAll(); 
      this.id = this.route.snapshot.params['id']; 
      this._TestService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){ 
              this.testDetails = response.data;               
              } else {
              this._AlertService.error(response.message);
            }    
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
      );  
  }  
  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getAll();
    }
  }
  getAll() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      isActive:this.isActive,
      question:this.question,
      testId : this.id = this.route.snapshot.params['id'],
    };
    this._QuestionService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){   
          this.questions = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

 


}
