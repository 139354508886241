import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../_alert';
import { ContactusService } from '../../_services/contactus.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  public allRecords;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;

  constructor(
    private _AlertService: AlertService,
    private _ContactusService: ContactusService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getRecords();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getRecords();
    }
  }

  getRecords() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
    };
    this._ContactusService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          console.log(response.data);
          this.allRecords = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize; 
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }


}
