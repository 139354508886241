import { Component, OnInit } from '@angular/core';

import { AlertService } from '../../_alert';
import { LibraryService } from '../../_services/library.service';
import { CourseService } from '../../_services/course.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public libraries;
  public page=1;
  public totalRecords;
  public pageSize;
  public currentPage=1;
  public isActive='';
  public docTitle='';
  public freeOrPaid='';
  public docType='';
  public courseFilter = '';
  public allCourses = [];
  public searchArr = [];

  constructor(
    private _AlertService: AlertService,
    private _LibraryService: LibraryService,
    private _CourseService:CourseService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getLibraries(); 
    this.getAllCourses();
  }
  getAllCourses() {
    this.spinner.show();
    this._CourseService.getAllList(this.searchArr).subscribe(
      (response: any) => {
        if (response.status == 200) {
          this.allCourses = response.data;
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err => { this.spinner.hide(); },
      () => this.spinner.hide()
    );
  } 
  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getLibraries();
    }
  }

  getLibraries() {
    this.spinner.show();
    let searchData = {
        currentPage:this.currentPage,
        freeOrPaid:this.freeOrPaid,
        docTitle:this.docTitle,
        isActive:this.isActive,
        docType:this.docType,
        courseFilter:this.courseFilter
    };
    this._LibraryService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.libraries = response.data; 
          this.page = response.pageNo; 
          this.totalRecords = response.totalRecord; 
          this.pageSize = response.pageSize;   
        } else {
          this._AlertService.error(response.message);
        }

         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onFilterByfreeOrPaid(key){
    if (key==''){
      this.freeOrPaid = '';
    } else {
      this.freeOrPaid = key;
    }
    this.getLibraries();
  }

  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getLibraries();
  }

  onFilterByDocTitle(key){
    if (key==''){
      this.docTitle = '';
    } else {
      this.docTitle = key;
    }
    this.getLibraries();
  }

  onFilterBydocType(key){
    if (key==''){
      this.docType = '';
    } else {
      this.docType = key;
    }
    this.getLibraries();
  }
  onFilterByCourse(course){
    if (course==''){
      this.courseFilter = '';
    } else {
      this.courseFilter = course;
    }
    this.getLibraries();

  }

}
