import { Component, OnInit, VERSION, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from '../../_alert';
import { TransactionService } from '../../_services/transaction.service';
import { NgxSpinnerService } from "ngx-spinner";
import { BatchService } from '../../_services/batch.service';
import { StudentService } from '../../_services/student.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
  
  @ViewChild("dateSelection") dateSelection: ElementRef;

  public transactions;
  public page=1;
  public fileName;
  public filePath;
  public totalTransactions;
  public pageSize;
  public courses;
  public students;
  public courseId='';
  public orderStatus='';
  public orderId='';
  public createdAt='';
  public studentId='';
  public currentPage=1;

  constructor(
    private _AlertService: AlertService,
    private _TransactionService: TransactionService,
    private _BatchService: BatchService,
    private _StudentService: StudentService,
    private spinner: NgxSpinnerService
    
  ) { }

  ngOnInit(): void {
    this.getTransactions();
    this.getAllCourses(); 
    this.getAllStudents(); 
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getTransactions();
    }
  }

  getTransactions() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      courseId:this.courseId,
      orderId:this.orderId,
      createdAt:this.createdAt,
      orderStatus:this.orderStatus,
      studentId:this.studentId,
    };
    this._TransactionService.getAll(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.transactions = response.data; 
          this.page = response.pageNo; 
          this.totalTransactions = response.totalRecord; 
          this.pageSize = response.pageSize;          
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 
 
  getAllCourses(){
    this.spinner.show();
    this._BatchService.getCourses().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.courses = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  getAllStudents(){
    this.spinner.show();
    this._StudentService.getAll().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.students = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onFilterByCourse(key){
    if (key==''){
      this.courseId = '';
    } else {
      this.courseId = key;
    }
    this.getTransactions();
  } 

  onFilterByStudent(key){
    if (key==''){
      this.studentId = '';
    } else {
      this.studentId = key;
    }
    this.getTransactions();
  } 

  onFilterByOrderStatus(key){
    if (key==''){
      this.orderStatus = '';
    } else {
      this.orderStatus = key;
    }
    this.getTransactions();
  }

  onFilterByOrderId(key){
    if (key==''){
      this.orderId = '';
    } else {
      this.orderId = key;
    }
    this.getTransactions();
  }   
  
  exportDataByFilters() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      courseId:this.courseId,
      orderId:this.orderId,
      orderStatus:this.orderStatus,
      studentId:this.studentId,
    };
    this._TransactionService.exportDataByFilters(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){  
          this.fileName = response.data;
          this.filePath = environment.apiUrl+'/uploads/'+this.fileName;
          window.open(this.filePath);
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 

  searchDataByFilters() {
    this.createdAt = this.dateSelection.nativeElement.value;
    this.getTransactions();
  }

}
