import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { BatchService } from '../../_services/batch.service';

@Component({
  selector: 'app-view',
  templateUrl: './view-student.component.html',
  styleUrls: ['./view-student.component.scss']
})
export class ViewStudentComponent implements OnInit {
  public students;
  id: string;
  details: {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _BatchService: BatchService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.getStudents();
    this.id = this.route.snapshot.params['id'];
    this._BatchService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.details = response.data;
            } else {
              this._AlertService.error(response.message);
            }
    
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
      
  }  
  
	  getStudents() {
		this.spinner.show();
		this._BatchService.getStudents().subscribe(
		  (response: any)  => {
			if(response.status==200){
			  this.students = response.data; 
			} else {
			  this._AlertService.error(response.message);
			}
			 this.spinner.hide();
		  },
		  err =>  {this.spinner.hide();},
		  () => this.spinner.hide()
		);
	  } 
   
  
              
   
  
   
            
         

}
