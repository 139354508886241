import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateGuard } from "../_guard/private.guard";

import { IndexComponent } from './index/index.component';
import { ViewComponent } from './view/view.component';


const routes: Routes = [
  { path: '', redirectTo: 'test-report/index', pathMatch: 'full'},
  { path: 'index/:id', component: IndexComponent, canActivate: [PrivateGuard] },
  { path: 'view/:testId/:studentId', component: ViewComponent, canActivate: [PrivateGuard] },
 
  { path: '', component: IndexComponent, canActivate: [PrivateGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestReportRoutingModule { }
