import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { PagesService } from '../../_services/pages.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  detailSlug = "Other";
  details: {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _PagesService: PagesService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      slug: ['', Validators.required],
      content: ['', Validators.required],
      our_feature_title: ['',],
      our_feature_title_1: ['',],
      our_feature_title_2: ['',],
      our_feature_title_3: ['',],
      our_feature_content_1: ['',],
      our_feature_content_2: ['',],
      our_feature_content_3: ['',],
      our_feature_title_4: ['',],
      our_feature_title_5: ['',],
      our_feature_title_6: ['',],
      our_feature_content_4: ['',],
      our_feature_content_5: ['',],
      our_feature_content_6: ['',],
      our_feature_title_7: ['',],
      our_feature_title_8: ['',],
      our_feature_title_9: ['',],
      our_feature_content_7: ['',],
      our_feature_content_8: ['',],
      our_feature_content_9: ['',],
      our_feature_title_10: ['',],
      our_feature_title_11: ['',],
      our_feature_title_12: ['',],
      our_feature_content_10: ['',],
      our_feature_content_11: ['',],
      our_feature_content_12: ['',],
      learn_better_title_1: ['',],
      learn_better_title_2: ['',],
      learn_better_title_3: ['',],
      learn_better_title_4: ['',],
      learn_better_content_1: ['',],
      learn_better_content_2: ['',],
      learn_better_content_3: ['',],
      learn_better_content_4: ['',],
      who_we_are_1: ['',],
      who_we_are_2: ['',],
      who_we_are_3: ['',],
      who_we_are_4: ['',],
      bring_title_1: ['',],
      bring_title_2: ['',],
      bring_title_3: ['',],
      bring_title_4: ['',],
      bring_content_1: ['',],
      bring_content_2: ['',],
      bring_content_3: ['',],
      bring_content_4: ['',],
      banner_line_1: ['',],
      banner_line_2: ['',],
      banner_line_3: ['',],
      banner_line_4: ['',],
      explore_content: ['',],
      explore_line_1: ['',],
      explore_line_2: ['',],
      explore_line_3: ['',],
      explore_line_4: ['',],
      video_url: ['',],
      address: ['',],
      explore_content_2: ['',],
      explore_line_5: ['',],
      explore_line_6: ['',],
      explore_line_7: ['',],
      our_stories_quote: ['',],
      our_stories_content: ['',],
      contact_number: ['',],
      email: ['',],
      isActive: true
    }, {

      });

    if (!this.isAddMode) {
      this._PagesService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              this.detailSlug = response.data.slug;
              this.details = response.data;
              this.form.patchValue(response.data);
            } else {
              this._AlertService.error(response.message);
            }

            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }

  onCancel() {
    this._location.back();
  }
  onSubmit() {
    if (this.form.value.title == '') {
      this._AlertService.error('Please enter title.');
      return false;
    }
    if (this.form.value.slug == '') {
      this._AlertService.error('Please enter slug.');
      return false;
    }
    if (this.form.value.content == '') {
      this._AlertService.error('Please enter content.');
      return false;
    }
    this.submitted = true;
    this.spinner.show();
    if (this.isAddMode) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this._PagesService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  private update() {
    this._PagesService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }

          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }


}
