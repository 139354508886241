 import { Component, OnInit, VERSION, ViewChild, ElementRef } from '@angular/core';

import { AlertService } from '../../_alert';
import { StudentService } from '../../_services/student.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../environments/environment';
import { CourseService } from '../../_services/course.service';
import { BatchService } from '../../_services/batch.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public environment = environment;  
  @ViewChild("dateSelection") dateSelection: ElementRef;
  @ViewChild("dateSelectiontodate") dateSelectiontodate: ElementRef;

  public startAt="";
  public todate="";
  public students;
  public fileName;
  public filePath;
  public page=1;
  public totalStudents;
  public currentPage=1;
  public pageSize=20;
  public isActive='';
  public firstName='';
  public studentAge = '';
  public batch='';
  public course='';
  public studentClass='';
  public courseFilter = '';
  public allCourses = [];
  public searchArr = [];
  dropdownBatches = [];
  constructor(
    private _AlertService: AlertService,
    private _StudentService: StudentService,
    private spinner: NgxSpinnerService,
    private _CourseService:CourseService,
    private _BatchService:BatchService,
    
  ) { }

  ngOnInit(): void {
      this.getStudents();
      this.getAllCourses();
      this.getActiveBatches();
  }

  getAllCourses() {
    this.spinner.show();
    this._CourseService.getAllList(this.searchArr).subscribe(
      (response: any) => {
        if (response.status == 200) {
          this.allCourses = response.data;
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err => { this.spinner.hide(); },
      () => this.spinner.hide()
    );
  } 

  getActiveBatches() {
    this.spinner.show();
    this._BatchService.getActiveBatches().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownBatches = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getStudents();
    }
  }

  getStudents() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      course:this.course,
      batch:this.batch,
      startAt:this.startAt,
      toDate:this.todate,
      isActive : this.isActive,
      pageSize : this.pageSize,
      firstName : this.firstName,
      studentClass : this.studentClass,
      studentAge : this.studentAge,
    };
    this.spinner.show();
    this._StudentService.getAllList(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.students = response.data; 
          this.page = response.pageNo; 
          this.totalStudents = response.totalRecord; 
          this.pageSize = response.pageSize;          
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 
 
  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getStudents();
  } 

  onFilterByStudentClass(key){
    if (key==''){
      this.studentClass = '';
    } else {
      this.studentClass = key;
    }
    this.getStudents();
  } 

  onFilterByStudentAge(key){
    if (key==''){
      this.studentAge = '';
    } else {
      this.studentAge = key;
    }
    this.getStudents();
  } 
  

  onFilterByName(key){
    if (key==''){
      this.firstName = '';
    } else {
      this.firstName = key;
    }
    this.getStudents();
  } 

  onFilterByPageSize(key){
    if (key==''){
      this.pageSize = 20;
    } else {
      this.pageSize = key;
    }
    this.getStudents();
  } 

  onFilterByBatch(key){
    if (key==''){
      this.batch = "";
    } else {
      this.batch = key;
    }
    this.getStudents();
  } 

  onFilterByCourse(key){
    if (key==''){
      this.course = "";
    } else {
      this.course = key;
    }
    this.getStudents();
  } 
 
 
  
  exportDataByFilters() {
    this.spinner.show();
    let searchData = {
      currentPage:this.currentPage,
      isActive : this.isActive,
      firstName : this.firstName,
      studentClass : this.studentClass,
      course:this.course,
      batch:this.batch,
      startAt:this.startAt,
      toDate:this.todate,
      studentAge : this.studentAge,
    };
    this._StudentService.exportDataByFilters(searchData).subscribe(
      (response: any)  => {
        if(response.status==200){  
          this.fileName = response.data;
          this.filePath = environment.apiUrl+'/uploads/'+this.fileName;
          window.open(this.filePath);
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 


  searchDataByFilters() {
    
    //alert(this.dateSelection.nativeElement.value);
    this.startAt = this.dateSelection.nativeElement.value;
    this.getStudents();
  }

  searchDataByFiltersTodate() {  
   // alert(this.dateSelectiontodate.nativeElement.value);
    this.todate = this.dateSelectiontodate.nativeElement.value;
    this.getStudents();
  }

}
