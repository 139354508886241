import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { BookingsService } from '../../_services/bookings.service';
import { CourseService } from '../../_services/course.service';

@Component({
  selector: 'app-view-bookings',
  templateUrl: './view-bookings.component.html',
  styleUrls: ['./view-bookings.component.scss']
})

export class ViewBookingsComponent implements OnInit {

  id: string;
  allBookings: {};
  form: FormGroup;
  submitted = false; 
  public allCourses;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _BookingsService: BookingsService,
    private _CourseService: CourseService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.getAllCourses(); 
    this.getStudentsBookingList();
    this.form = this.formBuilder.group({
      courseId: ['', Validators.required],
      studentId:[this.id, Validators.required],
      isActive: true,  
     },{ }); 
  }

  getStudentsBookingList() {
    this._BookingsService.bookingsByStudent(this.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if(response.status==200){
             this.allBookings = response.data;
          } else {
            this._AlertService.error(response.message);
          }    
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  deleteThisRecord(bookingId) {
    if(window.confirm('Are you sure you want delete?'))
      this.deleteThisBooking(bookingId);
  }

  deleteThisBooking(bookingId) {
    this.spinner.show();
    this._BookingsService.delete(bookingId).subscribe(
      (response: any)  => {
        if(response.status==200){
          this._AlertService.success(response.message);
          this.getStudentsBookingList();
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  getAllCourses() {
    this.spinner.show();
    this._CourseService.getAll().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.allCourses = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }
  
  onSubmit() {
    if (this.form.value.courseId == '') {
      this._AlertService.error('Please select course.');   
      return false;
    }
    this.submitted = true;
    this.spinner.show();
    this.create();
  }

  private create() {          
    this._BookingsService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this.getStudentsBookingList();
            this.getAllCourses();
          } else {
            this._AlertService.error(response.message);
          }  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }
  
}
