import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {UserService} from '../../_services/user.service';
import { AuthService } from '../../_services/auth.service';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public forgotPasswordForm: FormGroup;

  constructor(
    private _UserService: UserService,
    private _AuthService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private spinner: NgxSpinnerService
  ) { 
    
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
    });
  }

  public doForgotPasswordForm() {  
     let data = this.forgotPasswordForm.value;

    //validate
    if(this.forgotPasswordForm.value.username==''){
      this._AlertService.error('Username required.');
      return
    }

    data = {
      baseURL:location.origin,
      username:this.forgotPasswordForm.value.username
    }
 

    this.spinner.show();
  
    // Submit request to API
    this._UserService
      .forgotPassword(data)
      .subscribe(
        (response: any) => {
          if(response.status==200){
            this._AlertService.success('Please check your email to reset your password.');
          } else {
            this._AlertService.error(response.message);
          }
        },
        (error) => {
          this.spinner.hide();
        }, () => this.spinner.hide()
      );
  }

}
