import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { CourseService } from '../../_services/course.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  public categories;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _CourseService: CourseService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.getCategories();
    this.form = this.formBuilder.group({
      courseTitle: ['', Validators.required],
      studentAgeGroup: ['', Validators.required],
      duration: ['', Validators.required],
      subtitle: ['', Validators.required],
      description: ['', Validators.required],
      process_line_1: ['', Validators.required],
      process_line_2: ['', Validators.required],
      process_line_3: ['', Validators.required],
      process_line_4: ['', Validators.required],
      courseQuote: ['', Validators.required],
      curriculum_question_1: ['', Validators.required],
      curriculum_question_2: ['', Validators.required],
      curriculum_question_3: ['', Validators.required],
      curriculum_question_4: ['', Validators.required],
      curriculum_question_5: ['', Validators.required],
      curriculum_question_6: ['', Validators.required],
      curriculum_question_7: ['', Validators.required],
      curriculum_question_8: ['', Validators.required],
      curriculum_question_9: ['', Validators.required],
      curriculum_question_10: ['', Validators.required],
      curriculum_answer_1: ['', Validators.required],
      curriculum_answer_2: ['', Validators.required],
      curriculum_answer_3: ['', Validators.required],
      curriculum_answer_4: ['', Validators.required],
      curriculum_answer_5: ['', Validators.required],
      curriculum_answer_6: ['', Validators.required],
      curriculum_answer_7: ['', Validators.required],
      curriculum_answer_8: ['', Validators.required],
      curriculum_answer_9: ['', Validators.required],
      curriculum_answer_10: ['', Validators.required],
      category: ['', Validators.required],
      courseFees: ['', Validators.required],
      file: [""],
      isActive: true
    }, {

      });

    if (!this.isAddMode) {
      this._CourseService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if (response.status == 200) {
              this.form.patchValue(response.data);
              this.form.patchValue({ category: response.data.category._id });
            } else {
              this._AlertService.error(response.message);
            }

            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }
  getCategories() {
    this.spinner.show();
    this._CourseService.getAllCategories().subscribe(
      (response: any) => {
        if (response.status == 200) {
          this.categories = response.data;
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err => { this.spinner.hide(); },
      () => this.spinner.hide()
    );
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.courseTitle == '') {
      this._AlertService.error('Please enter course title.');
      return false;
    }

    if (this.form.value.studentAgeGroup == '') {
      this._AlertService.error('Please select student age group.');
      return false;
    }

    if (this.form.value.courseFees == '') {
      this._AlertService.error('Please enter course fees.');
      return false;
    }

    if (this.form.value.duration == '') {
      this._AlertService.error('Please enter course duration.');
      return false;
    }

    this.submitted = true;
    this.spinner.show();
    if (this.isAddMode) {
      this.create();
    } else {
      this.update();
    }
  }

  private create() {
    this._CourseService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }

          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  private update() {
    this._CourseService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }

          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  onFileChange(event) {
    const file = event.target.files[0];
    this.form.patchValue({ file: file });
  }


}
