import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {UserService} from '../_services/user.service';
import { AuthService } from '../_services/auth.service';
import {Location} from '@angular/common';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  public frm: FormGroup;

  constructor(
    private _UserService: UserService,
    private _AuthService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private _location: Location

  ) { 
  } 
  /*
  ngOnInit() { }
  public cancel() { 
   this._location.back(); 
  }
  public logout() {   
    this._UserService.logout();
    this.router.navigate(['/home']);
  }*/


ngOnInit() { 
  if(window.confirm('Are you sure you want logout?')){
    this._UserService.logout();
    this.router.navigate(['/user/login']);
  }
  else{
    this._location.back(); 
  }
}


}
