import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  constructor(private _ApiService: ApiService) { }

  getAll(searchData) {
    return this._ApiService.post('teacherslist', searchData);
  }

  getById(id) {
    return this._ApiService.get('teachers/'+ id);
  }

  create(data) {
      return this._ApiService.post('teachers', data);
  }

  update(id, data) {
      return this._ApiService.post('teachers/update/' + id, data);
  }
  changePassword(id,data){
    return this._ApiService.put('teachers/change-password/' + id, data);
  }

  delete(id) {
      return this._ApiService.delete('teachers/' + id);
  }
}
