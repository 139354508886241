import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';

import { AlertService } from '../../_alert';
import { BannersService } from '../../_services/banners.service';

import { NgxSpinnerService } from "ngx-spinner";

@Component({
  //selector: 'app-delete',
  //templateUrl: './delete.component.html',
  //styleUrls: ['./delete.component.scss']
})
export class DeleteComponent implements OnInit {
  id: string;

  constructor(
    private _AlertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private _BannersService: BannersService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];

    if(window.confirm('Are you sure you want delete?'))
      this.deleteCourse();
    else
      this._location.back();
  }

  deleteCourse() {
    this.spinner.show();
    this._BannersService.delete(this.id).subscribe(
      (response: any)  => {
        if(response.status==200){
          this._AlertService.success(response.message);
          this._location.back();
        } else {
          this._AlertService.error(response.message);
        }

         this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

}
