import { Component, OnInit, VERSION, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from '../../_alert';
import { DoutService } from '../../_services/dout.service';
import { NgxSpinnerService } from "ngx-spinner";
import { LiveClassService } from '../../_services/live-class.service';
import { BatchService } from '../../_services/batch.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {
  
  @ViewChild("dateSelection") dateSelection: ElementRef;
  
  public doubts;
  dropdownClasses = [];
  dropdownStudents = [];
  dropdownTeachers = [];
  dropdownBatches = [];
  public page=1;
  public totalDoubts;
  public pageSize;
  public totalResolved;
  public totalPending;
  public currentPage=1;
  public classId = '';
  public studentId = '';
  public batchId = '';
  public createdAt = '';
  public teacherId = '';
  public isActive = '';
  constructor(
    private _AlertService: AlertService,
    private _DoutService: DoutService,
    private _LiveClassService: LiveClassService,
    private _BatchService: BatchService,
    private spinner: NgxSpinnerService    
  ) { }

  ngOnInit(): void {
    this.getDouts();
    this.getAllClasses();
    this.getStudents();
    this.getActiveBatches();
    this.getTeachers();
  }

  loadPage(page: number) {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.getDouts();
    }
  }

  getDouts() {
    let data = {
      currentPage : this.currentPage,
      classId : this.classId,
      studentId : this.studentId,
      batchId : this.batchId,
      createdAt : this.createdAt,
      teacherId : this.teacherId,
      isActive : this.isActive,
    };
    this.spinner.show();
    this._DoutService.getDoutsByFilter(data).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.doubts = response.data; 
          this.page = response.pageNo; 
          this.totalDoubts = response.totalRecord; 
          this.totalResolved = response.totalResolved; 
          this.totalPending = response.totalPending; 
          this.pageSize = response.pageSize;          
        } else {
          this._AlertService.error(response.message);
        }
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 
  
  getAllClasses() {
    this.spinner.show();
    this._LiveClassService.getAllClasses().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownClasses = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 

  getStudents() {
    this.spinner.show();
    this._BatchService.getStudents().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownStudents = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 

  getTeachers() {
    this.spinner.show();
    this._BatchService.getTeachers().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownTeachers = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 
   
  onFilterSelect(key){
    if (key==''){
      this.classId = '';
    } else {
      this.classId = key;
    }
    this.getDouts();
  } 

  onFilterByStudent(key){
    if (key==''){
      this.studentId = '';
    } else {
      this.studentId = key;
    }
    this.getDouts();
  }

  onFilterByTeacher(key){
    if (key==''){
      this.teacherId = '';
    } else {
      this.teacherId = key;
    }
    this.getDouts();
  } 

  onFilterByStatus(key){
    if (key==''){
      this.isActive = '';
    } else {
      this.isActive = key;
    }
    this.getDouts();
  } 
  
  getActiveBatches() {
    this.spinner.show();
    this._BatchService.getActiveBatches().subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownBatches = response.data; 
        } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onFilterByBatch(key){
    if (key==''){
      this.batchId = '';
    } else {
      this.batchId = key;
    }
    this.getDouts();
  }

  searchDataByFilters() {
    this.createdAt = this.dateSelection.nativeElement.value;
    this.getDouts();
  }


}
