import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { DoutService } from '../../_services/dout.service';

@Component({
  selector: 'app-viewdouts',
  templateUrl: './viewdouts.component.html',
  styleUrls: ['./viewdouts.component.scss']
})

export class ViewdoutsComponent implements OnInit {

  id: string;
  douts: [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _DoutService: DoutService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this._DoutService.getDoutsByTask(this.id)
      .pipe(first())
      .subscribe(
        (response: any) => {
          if(response.status==200){
            this.douts = response.data;
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }


}