import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { NgxSpinnerService } from "ngx-spinner";
import { LiveClassService } from '../../_services/live-class.service';
import { BatchService } from '../../_services/batch.service';
import { CourseService } from '../../_services/course.service';

@Component({
  selector: 'app-create-update',
  templateUrl: './create-update.component.html',
  styleUrls: ['./create-update.component.scss']
})
export class CreateUpdateComponent implements OnInit {

  form: FormGroup;
  id: string;
  isAddMode: boolean;
  submitted = false;
  dropdownTeachers = [];
  dropdownBatches = [];
  dropdownCourses = [];
  searchArr = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private _AlertService: AlertService,
    private _LiveClassService: LiveClassService,
    private _BatchService: BatchService,
    private _CourseService: CourseService,
    private spinner: NgxSpinnerService,
    private _location: Location
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;

   // this.getActiveBatches();
    this.getActiveCourses();

    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      startAt: ['', Validators.required],
      duration: ['60', Validators.required],
      teacher: ['', Validators.required],
      batch: ['', Validators.required],
      course: ['', Validators.required],
      isActive: true
    }, {

      });

    if (!this.isAddMode) {
      this._LiveClassService.getById(this.id)
        .pipe(first())
        .subscribe(
          (response: any) => {
            if(response.status==200){
              this.form.patchValue(response.data);
              this.form.patchValue({teacher: response.data.teacher._id});
              this.form.patchValue({batch: response.data.batch._id});
              this.form.patchValue({course: response.data.course._id});
              this.getActiveBatches();
              this.getTeachersByBatch();
            } else {
              this._AlertService.error(response.message);
            }    
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
          }
        );
    }
  }

  getActiveCourses() {
    this.spinner.show();
    this._CourseService.getAllList(this.searchArr).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownCourses = response.data; 
        } else {
          this._AlertService.error(response.message);
        } 
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  getActiveBatches() {
    //console.log(this.form.value.course); return false;
    this.spinner.show();
    this._BatchService.getAllBatchByCourse(this.form.value.course).subscribe(
      (response: any)  => {
        if(response.status==200){ 
          this.dropdownBatches = response.data; 
         } else {
          this._AlertService.error(response.message);
        }
        
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  } 

  getTeachersByBatch() {
    this.spinner.show();
    this._BatchService.getTeachersByBatch(this.form.value.batch).subscribe(
      (response: any)  => {
        if(response.status==200){
          this.dropdownTeachers = response.data; 
        } else {
          this._AlertService.error(response.message);
        } 
        this.spinner.hide();
      },
      err =>  {this.spinner.hide();},
      () => this.spinner.hide()
    );
  }

  onCancel() {
    this._location.back();
  }

  onSubmit() {
    if (this.form.value.batch == '') {
      this._AlertService.error('Please select batch.');      
      return false;
    }
    if (this.form.value.teacher == '') {
      this._AlertService.error('Please select teacher.');      
      return false;
    }
    if (this.form.value.title == '') {
      this._AlertService.error('Please enter the class title.');      
      return false;
    }if (this.form.value.description == '') {
      this._AlertService.error('Please enter the class description.');      
      return false;
    } if (this.form.value.startAt == '') {
      this._AlertService.error('Please select date & time.');   
      return false;
    } 
    this.submitted = true;
    this.spinner.show();
    if (this.isAddMode) {
      this.create();
    } else {
      this.update();
    }
  }
  onCourseChange(newValue) {  
   this.getActiveBatches();
  // this.getTeachersByBatch();
  }
  onBatchChange(newValue) {
    this.getTeachersByBatch();
  }

  private create() {
    this._LiveClassService.create(this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }

  private update() {
    this._LiveClassService.update(this.id, this.form.value)
      .pipe(first())
      .subscribe({
        next: (response: any) => {
          if(response.status==200){
            this._AlertService.success(response.message);
            this._location.back();
          } else {
            this._AlertService.error(response.message);
          }
  
          this.spinner.hide();
        },
        error: error => {
          this.spinner.hide();
        }
      });
  }
}
